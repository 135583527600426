import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const WalusPage = () => (
  <Layout>
    <Seo title="Walus" />
    <h1>mgr Bożena Waluś</h1>
    <h3>Psycholog, Psychoterapeuta</h3>
    <p>Psycholog/psychoterapeuta poznawczo – behawioralny, wykładowca akademicki w WSB w Gliwicach. Ukończyła psychologię w obszarze klinicznym (specjalność psychologia kliniczna) na Uniwersytecie SWPS we Wrocławiu. Ukończyła całościowe szkolenie psychoterapeutyczne rekomendowane przez Polskie Towarzystwo Terapii Poznawczej i Behawioralnej w Warszawskim CBT. Jest członkiem Polskiego Towarzystwa Terapii Poznawczej i Behawioralnej. Pracuje z osobami dorosłymi i młodzieżą, które znalazły się w trudnej sytuacji życiowej, mają problemy w relacjach interpersonalnych, żyją pod wpływem silnego stresu lub cierpią z powodu zaburzeń lękowych, nastroju (depresja) oraz zaburzeń osobowości. A także wspiera osoby, które poszukują nowych dróg rozwoju i pragną lepiej poznać siebie. Swoją pracę poddaje regularnej superwizji u certyfikowanych superwizorów PTTPB.</p>
    <StaticImage
      src="../images/talk1.jpg"
      width={300}
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt="Talk"
      style={{ marginBottom: `1.45rem` }}
    />
  </Layout>
)

export default WalusPage
